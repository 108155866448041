/* eslint-disable */
import HttpService from '../HttpService'
//import axios from "axios";

export default class FileDataService {
  constructor() {
    this._httpService = new HttpService('/payroll');
    //this._httpService = axios.create({ baseURL: 'http://localhost:8081/api/payroll' });
  }

  async ReloadContext(){
    return await this._httpService.get('/reload');
  }
}
