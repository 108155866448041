<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    content-class="CrontabModal"
    persistent>
    <v-card
      class="rounded-xl elevation-0">
      <v-card-title
        class="justify-space-between px-9">
        <span
          class="text-h5">
          Crontab
        </span>
        <v-btn
          icon
          @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="pb-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                class="cronHour"
                v-model="cron.hour"
                :items="hours"
                :disabled="ignore.hour"
                label="Hora"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.hour ? 'success' : ''"
                          @click="
                            repeat.hour
                              ? desactivateRepeatCronField('hour')
                              : activateRepeatCronField('hour')"
                          :disabled="ignore.hour"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.hour
                          ? 'Ativar repetição do campo Hora'
                          : 'Desativar repetição do campo Hora'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.hour ? 'success' : ''"
                          @click="ignoreField('hour')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.hour
                          ? 'Ignorar campo Hora'
                          : 'Ativar campo Hora'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="cron.minute"
                :items="minutes"
                :disabled="ignore.minute"
                label="Minuto"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.minute ? 'success' : ''"
                          @click="
                            repeat.minute
                              ? desactivateRepeatCronField('minute')
                              : activateRepeatCronField('minute')"
                          :disabled="ignore.minute"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.minute
                          ? 'Ativar repetição do campo Minuto'
                          : 'Desativar repetição do campo Minuto'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.minute ? 'success' : ''"
                          @click="ignoreField('minute')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.minute
                          ? 'Ignorar campo Minuto'
                          : 'Ativar campo Minuto'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="cron.second"
                :items="seconds"
                :disabled="ignore.second"
                label="Segundo"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.second ? 'success' : ''"
                          @click="
                            repeat.second
                              ? desactivateRepeatCronField('second')
                              : activateRepeatCronField('second')"
                          :disabled="ignore.second"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.second
                          ? 'Ativar repetição do campo Segundo'
                          : 'Desativar repetição do campo Segundo'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.second ? 'success' : ''"
                          @click="ignoreField('second')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.second
                          ? 'Ignorar campo Segundo'
                          : 'Ativar campo Segundo'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="cron.dayOfMonth"
                :items="daysOfMonth"
                :disabled="ignore.dayOfMonth"
                label="Dia do Mês"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.dayOfMonth ? 'success' : ''"
                          @click="
                            repeat.dayOfMonth
                              ? desactivateRepeatCronField('dayOfMonth')
                              : activateRepeatCronField('dayOfMonth')"
                          :disabled="ignore.dayOfMonth"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.dayOfMonth
                          ? 'Ativar repetição de Dia do Mês'
                          : 'Desativar repetição de Dia do Mês'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.dayOfMonth ? 'success' : ''"
                          @click="ignoreField('dayOfMonth')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.dayOfMonth
                          ? 'Ignorar campo Dia do Mês'
                          : 'Ativar campo Dia do Mês'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="cron.month"
                :items="months"
                :disabled="ignore.month"
                label="Mês"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.month ? 'success' : ''"
                          @click="
                            repeat.month
                              ? desactivateRepeatCronField('month')
                              : activateRepeatCronField('month')"
                          :disabled="ignore.month"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.month
                          ? 'Ativar repetição do campo Mês'
                          : 'Desativar repetição do campo Mês'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.month ? 'success' : ''"
                          @click="ignoreField('month')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.month
                          ? 'Ignorar campo Mês'
                          : 'Ativar campo Mês'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="cron.year"
                :items="years"
                :disabled="ignore.year"
                label="Ano"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.year ? 'success' : ''"
                          @click="
                            repeat.year
                              ? desactivateRepeatCronField('year')
                              : activateRepeatCronField('year')"
                          :disabled="ignore.year"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.year
                          ? 'Ativar repetição do campo Ano'
                          : 'Desativar repetição do campo Ano'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.year ? 'success' : ''"
                          @click="ignoreField('year')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.year
                          ? 'Ignorar campo Ano'
                          : 'Ativar campo Ano'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="cron.dayOfWeek"
                :items="daysOfWeek"
                :disabled="ignore.dayOfWeek"
                label="Dia da Semana"
                multiple
                chips
                deletable-chips
                v-bind="inputAttributes"
                @change="cronUpdate">
                <template
                  v-slot:append-outer>
                  <div
                    class="d-flex flex-row">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="repeat.dayOfWeek ? 'success' : ''"
                          @click="
                            repeat.dayOfWeek
                              ? desactivateRepeatCronField('dayOfWeek')
                              : activateRepeatCronField('dayOfWeek')"
                          :disabled="ignore.dayOfWeek"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-repeat
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !repeat.dayOfWeek
                          ? 'Ativar repetição do campo Dia da Semana'
                          : 'Desativar repetição do campo Dia da Semana'
                        }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :color="ignore.dayOfWeek ? 'success' : ''"
                          @click="ignoreField('dayOfWeek')"
                          v-on="on"
                          v-bind="attrs">
                          <v-icon>
                            mdi-cancel
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          !ignore.dayOfWeek
                          ? 'Ignorar campo Dia da Semana'
                          : 'Ativar campo Dia da Semana'
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="cronString"
                v-bind="inputAttributes"
                @input="cronStringUpdate"
                :loading="loadingCronString"
                :dense="false"
                class="grey lighten-2 d-flex align-center cronString">
                <!-- <template
                  v-slot:append>
                  <v-btn
                    icon
                    @click="copyCronString">
                    <v-icon>
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template> -->
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions
        class="pb-5 px-9">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="clearCron">
          Limpar
        </v-btn>
        <v-btn
          color="primary"
          @click="confirmCron">
          Concluir
        </v-btn>
      </v-card-actions>
    </v-card>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />
  </v-dialog>
</template>

<script>
import SnackbarCustomize from '../CustomAlerts/SnackbarCustomize.vue';

export default {
  name: 'ContrabModal',

  components: {
    SnackbarCustomize
  },

  data() {
    return {
      dialog: false,
      loadingCronString: false,
      cron: {
        second: [],
        hour: [],
        minute: [],
        dayOfMonth: [],
        month: [],
        dayOfWeek: [],
        year: [],
      },
      cronString: "",
      hours: Array.from({ length: 24 }, (_, i) => i.toString()),
      minutes: Array.from({ length: 60 }, (_, i) => i.toString()),
      seconds: Array.from({ length: 60 }, (_, i) => i.toString()),
      daysOfMonth: Array.from({ length: 31 }, (_, i) => (i+1).toString()),
      years: Array.from({ length: new Date().getFullYear() - 1990 + 1 }, (_, i) => (1990 + i).toString()),
      months: [
        { text: 'Janeiro', value: '1' },
        { text: 'Fevereiro', value: '2' },
        { text: 'Março', value: '3' },
        { text: 'Abril', value: '4' },
        { text: 'Maio', value: '5' },
        { text: 'Junho', value: '6' },
        { text: 'Julho', value: '7' },
        { text: 'Agosto', value: '8' },
        { text: 'Setembro', value: '9' },
        { text: 'Outubro', value: '10' },
        { text: 'Novembro', value: '11' },
        { text: 'Dezembro', value: '12' },
      ],
      daysOfWeek: [
        { text: 'Domingo', value: '0' },
        { text: 'Segunda', value: '1' },
        { text: 'Terça', value: '2' },
        { text: 'Quarta', value: '3' },
        { text: 'Quinta', value: '4' },
        { text: 'Sexta', value: '5' },
        { text: 'Sábado', value: '6' },
      ],
      inputAttributes: {
        outlined: true,
        dense: true,
        hideDetails: true,
      },
      repeat: {
        second: false,
        hour: false,
        minute: false,
        dayOfMonth: false,
        month: false,
        dayOfWeek: false,
        year: false,
      },
      ignore: {
        second: false,
        hour: false,
        minute: false,
        dayOfMonth: false,
        month: false,
        dayOfWeek: false,
        year: false,
      },
    };
  },

  props: {
    separator: {
      type: String,
      default: '+'
    },
  },

  methods: {
    activateRepeatCronField(fieldName) {
      this.repeat[fieldName] = true;

      this.cron[fieldName] = [];

      this.cronUpdate();
    },

    desactivateRepeatCronField(fieldName) {
      this.repeat[fieldName] = false;

      this.cronUpdate();
    },

    bootCronString() {
      const parts = this.cronString.trim().split(this.separator);

      this.setCron(parts);
    },

    clearCron() {
      this.cron = {
        second: [],
        hour: [],
        minute: [],
        dayOfMonth: [],
        month: [],
        dayOfWeek: [],
        year: [],
      };

      this.repeat = {
        second: false,
        hour: false,
        minute: false,
        dayOfMonth: false,
        month: false,
        dayOfWeek: false,
        year: false,
      };

      this.ignore = {
        second: false,
        hour: false,
        minute: false,
        dayOfMonth: false,
        month: false,
        dayOfWeek: false,
        year: false,
      };

      this.cronUpdate();
    },

    closeDialog() {
      this.dialog = false;
    },

    confirmCron() {
      this.$emit('setCronValue', this.cronString);

      this.closeDialog();
    },

    copyCronString() {
      navigator.clipboard.writeText(this.cronString).then(() => {
        this.$refs.SnackbarCustomize.open(
          'success',
          'Crontab copiado!'
        );
      });
    },

    cronUpdate() {
      let parts = this.setCronString().trim().split(this.separator);

      this.setCron(parts);
    },

    cronStringUpdate() {
      clearTimeout(this.debounceTimeout);

      this.loadingCronString = true;

      this.debounceTimeout = setTimeout(() => {
        const parts = this.cronString.trim().split(this.separator);

        this.setCron(parts);

        this.loadingCronString = false;
      }, 2000);
    },

    ignoreField(fieldName) {
      this.ignore[fieldName] = !this.ignore[fieldName];

      if (this.ignore[fieldName] == false) {
        this.cron[fieldName] = [];
      }

      this.cronUpdate();
    },

    open(cron) {
      this.dialog = true;

      this.cronString = cron;

      this.bootCronString();
    },

    setCron(parts) {
      const fields = ['second', 'minute', 'hour', 'dayOfMonth', 'month', 'dayOfWeek', 'year'];

      if (Object.keys(parts).length === 7) {
        fields.forEach((field, index) => {
          if (parts[index] === '?') {
            this.repeat[field] = false;

            this.ignore[field] = true;
          } else {
            this.ignore[field] = false;
          }

          if (parts[index].includes('0/')) {
            this.repeat[field] = true;

            this.ignore[field] = false;
          } else {
            this.repeat[field] = false;
          }

          if (this.repeat[field] === true) {
            let partValue = ''

            if (parts[index].includes('0/')) {
              partValue = parts[index].replace("0/", "").toString();

              partValue = partValue === "*" ? [] : partValue.split(",")
            } else {
              partValue = parts[index] === "*" ? [] : parts[index].split(",")
            }

            const lastValue = partValue[partValue.length-1];

            this.cron[field] = parts[index] === "*" ? [] : [lastValue];
          } else {
            this.cron[field] = parts[index] === "*" ? [] : parts[index].split(",");
          }
        })
      }

      if (parts.length === 1 && !parts[1]) {
        fields.forEach((field) => {
          this.cron[field] = [];
        });
      }

      setTimeout(() => {
        this.cronString = this.setCronString().trim();
      }, 200)
    },

    setCronString() {
      const second = this.setCronValue('second');
      const minute = this.setCronValue('minute');
      const hour = this.setCronValue('hour');
      const dayOfMonth = this.setCronValue('dayOfMonth');
      const month = this.setCronValue('month');
      const dayOfWeek = this.setCronValue('dayOfWeek');
      const year = this.setCronValue('year');

      return `
      ${
        second
      }${this.separator}${
        minute
      }${this.separator}${
        hour
      }${this.separator}${
        dayOfMonth
      }${this.separator}${
        month
      }${this.separator}${
        dayOfWeek
      }${this.separator}${
        year
      }`;
    },

    setCronValue(field) {
      if (this.ignore[field] === true) return '?';

      if (this.repeat[field] === true) {
        return `0/${this.cron[field]}`
      } else {
        return this.cron[field].join(",") || "*";
      }
    },
  },
};
</script>

<style>
.CrontabModal {
  box-shadow: unset !important;
}

.cronHour .v-input__append-outer {
  margin-top: 0 !important;
}

.cronString .v-input__append-inner {
  margin-top: 0;
  display: flex;
  align-items: center;
  height: 56px;
}

.cronString .v-input__control .v-input__slot {
  display: flex;
  flex: 1 1 auto;
}

</style>
