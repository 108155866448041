<template>
  <div>
    <v-row class="mb-3">
      <h2>Tipo de Integração: <b>Sharepoint</b></h2>
    </v-row>
    <v-tabs
      color="primary"
      background-color="var(--v-background-base)"
      v-model="tab"
      align-with-title
    >
      <v-tab style="font-size: 22px">Obrigatórias</v-tab>
      <v-tab style="font-size: 22px">Avançadas</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      grow
      style="background-color: var(--v-background-base)"
    >
      <v-tab-item>
        <v-row>
          <v-col cols="2"><label class="label">URL (hostname)*</label></v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"
            ><label class="label"
              >Username - Basic Authentication*</label
            ></v-col
          >
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"
            ><label class="label"
              >Password - Basic Authentication*</label
            ></v-col
          >
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"><label class="label">Token*</label></v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"><label class="label">Refresh Token*</label></v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"><label class="label">HTTP Method*</label></v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"><label class="label">Body*</label></v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"><label class="label">Content Type*</label></v-col>
          <v-col cols="4">
            <v-btn-toggle dense>
              <div style="background-color: var(--v-background-base)">
                <v-btn small active-class="primary" class="mr-2">Text</v-btn>
                <v-btn small active-class="primary" class="mr-2"
                  >JavaScript</v-btn
                >
                <v-btn small active-class="primary" class="mr-2">Json</v-btn>
                <v-btn small active-class="primary" class="mr-2">HTML</v-btn>
                <v-btn small active-class="primary">XML</v-btn>
              </div>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2"><label class="label">HTTP Headers*</label></v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Proxy configuration service</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Proxy Host</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Proxy Port</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Proxy Type</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Proxy Username</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Proxy Password</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Follow Redirects</label>
            </div>
          </v-col>
          <v-col cols="4">
            <v-btn-toggle dense>
              <div style="background-color: var(--v-background-base)">
                <v-btn small active-class="primary" min-width="158" class="mr-6"
                  >True</v-btn
                >
                <v-btn small active-class="primary" min-width="158" class="mr-6"
                  >False</v-btn
                >
              </div>
            </v-btn-toggle></v-col
          >
        </v-row>

        <v-row>
          <v-col cols="2">
            <div>
              <label class="label">Timeout</label>
            </div>
          </v-col>
          <v-col cols="4"><v-text-field solo></v-text-field></v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  name: 'Sharepoint',
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style scoped></style>
