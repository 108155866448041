import { render, staticRenderFns } from "./Webservice.vue?vue&type=template&id=842b2090&scoped=true&"
import script from "./Webservice.vue?vue&type=script&lang=js&"
export * from "./Webservice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "842b2090",
  null
  
)

export default component.exports