<template>
  <div>
    <v-row class="mb-3">
      <h2>Tipo de Integração: <b>Webservice</b></h2>
    </v-row>
    <v-row>
      <v-col cols="2"
        ><label class="label">URL (Endereço do Endpoint)*</label></v-col
      >
      <v-col cols="4"><v-text-field solo></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><label class="label">Token*</label></v-col>
      <v-col cols="4"><v-text-field solo></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"><label class="label">Refresh Token*</label></v-col>
      <v-col cols="4"><v-text-field solo></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"
        ><label class="label">Username - Basic Authentication*</label></v-col
      >
      <v-col cols="4"><v-text-field solo></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"
        ><label class="label">Password - Basic Authentication*</label></v-col
      >
      <v-col cols="4"><v-text-field solo></v-text-field></v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'Webservices',
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style scoped></style>
